<template>
  <div
    class="custom-select"
    :class="{ 'custom-select--open': isOpen }"
    v-click-outside="closeCustomSelect"
  >
    <div
      class="custom-select-input"
      @click="toggleCustomSelect"
    >
      <div class="custom-select-input__label">{{ labelText }}</div>

      <div class="custom-select-input__icon">
        <atomic-icon id="arrow_expand-close" />
      </div>
    </div>

    <list-filterable-check-box
      v-if="isOpen"
      v-model:selectedOptions="selectedOptions"
      :options="options"
      :identifierKey="identifierKey"
      :labelKey="labelKey"
      :customClass="customClass"
      @onSave="closeCustomSelect"
    />
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    options: any[];
    label: string,
    identifierKey?: string;
    labelKey?: string;
    customClass?: string;
  }>(),
  {
    options: () => [],
    identifierKey: 'identity',
    labelKey: 'name',
    customClass: '',
  }
);

const selectedOptions = defineModel('selectedOptions', {
  type: Array as () => string[],
  default: () => [],
});

const isOpen = ref(false)

const labelText = computed(() => {
  return selectedOptions.value.length
    ? props.options
      .filter((item) => selectedOptions.value.includes(item[props.identifierKey]))
      .map((item) => item.name)
      .join(', ')
    : props.label
})

const toggleCustomSelect = () => {
  isOpen.value = !isOpen.value
}

const closeCustomSelect = () => {
  isOpen.value = false
}
</script>

<style src="~/assets/styles/components/selects/custom-select/custom-select.scss" lang="scss" />
